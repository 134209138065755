@font-face {
  font-family: bebaspro;
  src: url(../fonts/BebasNeuePro-Regular.ttf);
}

body {
  font-family: bebaspro !important;
}

.big-title {
  font-family: bebaspro !important;
}

.banner-home-swiper .swiper-slide.swiper-slide:before {
  /* background: linear-gradient(90deg, rgba(4, 30, 66, 0.9) 0%, rgb(83 100 141 / 74%) 15%, rgb(228 0 43 / 29%) 100%) !important; */
  background: linear-gradient(
    95deg,
    rgba(4, 40, 89, 0.805) 0%,
    rgba(30, 48, 95, 0.546) 15%,
    rgba(228, 0, 42, 0.09) 100%
  ) !important;
}

header#main-header .c-mainheader {
  background: #041e4252 !important;
}

.w-trailor {
  font-weight: 600 !important;
}

footer.footer-one .footer-top .info-share li a {
  background: #041e42 !important;
}

.parallax-window::after {
  background: rgb(3 30 66 / 68%) !important;
}

header .navbar-light .navbar-brand img.logo {
  width: 2.5em !important;
}

#loading {
  background: #031c42 url(../images/break-loader.png) no-repeat scroll center
    center;
}

.logo-text {
  letter-spacing: 1.5px;
}

.welcome-text-div {
  position: absolute !important;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.text-secondary {
  color: #041e42 !important;
}

.login-logo {
  margin-top: -85px;
}

.border-radius-6 {
  border-radius: 6px;
}

.top-10-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  transform-style: preserve-3d;
  justify-content: center;
}

.item {
  position: relative;
  display: flex;
  align-items: flex-end;
  transform-style: preserve-3d;
  transform: rotateY(-0.01deg);
}

.item div {
  font-weight: bold;
  font-size: 115px;
  line-height: 1;
  margin-bottom: -20px;
  color: #000813;
  -webkit-text-stroke: 0.1px #ffffffe6;
  text-stroke: 0.1px #ffffffe6;
  text-shadow: 0 0 30px black;
  position: absolute;
  left: -14px;
  z-index: 9999;
}

#top-10 li.slide-item .block-images {
  overflow: visible !important;
}

#top-10 li.slide-item .block-images::after {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgb(20 20 20 / 71%) 50%,
    rgb(110 124 158 / 0%) 100%
  ) !important;
}

.block-social-info {
  align-items: flex-start !important;
  top: 20px !important;
}

/* .item img {
    margin: 4px 0 4px 44px;
  } */

#top-10 .block-description {
  left: 4em !important;
}

.c-logo {
  width: 4.2em !important;
}

.navbar-toggler {
  padding: 0 !important;
}

.navbar-light .navbar-toggler-icon {
  background: url(../images/menu-toggle.png) no-repeat scroll center center !important;
}

input,
input[type="text"] {
  background-color: #fff !important;
}

.iq-custom-select .select2-container--bootstrap4 .select2-selection,
.select2-dropdown {
  color: #000813 !important;
}

.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b:before {
  color: #000813 !important;
}

.leaderboard {
  padding: 80px 0;
}

.glass-card {
  background: white;
  background: linear-gradient(to right bottom, #3b3e99, #330d62);
  backdrop-filter: blur(1rem);
  z-index: 1;
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
}

.leaderboard table th,
td {
  border: 0 none !important;
}

.leaderboard table th {
  font-weight: bold !important;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.name-circle {
  width: 40px;
  height: 40px;
  background-color: #fff;
  padding: 8px;
  border-radius: 50%;
  color: #000813;
  font-weight: 600;
}

.name {
  letter-spacing: 1.2px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.number {
  padding: 6px 12px;
  border-radius: 2px;
  font-size: 20px;
}

.score {
  font-size: 20px;
  letter-spacing: 1.2px;
}

.rank-1 {
  background-color: #ffab00;
  color: #fff;
}

.rank-2 {
  background-color: #64dd17;
  color: #fff;
}

.rank-3 {
  background-color: #ad4bff;
  color: #fff;
}

.when-profile-not-complete .swiper-slide {
  opacity: 0.3;
}

.select2-results {
  background-color: #fff !important;
}

input,
input[type="text"] {
  color: #000000 !important;
}

.select2-container--bootstrap4
  .select2-dropdown
  .select2-results__option[aria-selected="true"] {
  background-color: #ff0000 !important;
}

.searchbox img {
  position: absolute;
  top: 20px;
  left: 10px;
}

.iq-search-bar .search-input {
  color: #000 !important;
}

.iq-search-bar .search-input {
  height: 4em !important;
  font-size: 16px;
}

.iq-search-bar .search-input::placeholder {
  color: #000 !important;
  font-size: 16px;
}

.setting-wrapper {
  padding: 150px 0 !important;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  border: 0.063em solid var(--iq-body-text) !important;
}

.game-description {
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(
    95deg,
    rgba(4, 40, 89, 0.805) 0%,
    rgba(30, 48, 95, 0.546) 15%,
    rgba(228, 0, 42, 0.09) 100%
  ) !important;
}

.description-text {
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
}

.games-banner .swiper-banner-container {
  margin-top: 60px !important;
  height: 100% !important;
}

.games-container .swiper-banner-button-prev {
  top: 45%;
  position: absolute;
  right: 0;
}

.games-container .swiper-banner-button-next {
  top: 45%;
  position: absolute;
  left: 0;
}

.home-slider .swiper-bg {
  /* margin-top: 60px !important; */
}

.block-description {
  left: 3em !important;
}

.language .switch {
  position: relative;
  display: inline-block;
}

.language .check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.language input.check-toggle-round-flat + label {
  padding: 0;
  width: 65px;
  height: 32px;
  background-color: #920019;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.language .switch > span.on {
  left: 0;
  padding-left: 6px;
  color: #e40029;
}
.language .switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.language .switch > span {
  position: absolute;
  top: 10px;
  pointer-events: none;
  font-family: "Helvetica", Arial, sans-serif;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  width: 50%;
  text-align: center;
}

.language input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #920019;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.language input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 28px;
}

input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 6px;
  bottom: 4px;
  width: 25px;
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

.language input.check-toggle-round-flat + label:before,
input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
.language input.check-toggle-round-flat:checked ~ .off {
  color: #e40029;
}

.language .switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

@media (max-width: 990px) {
  .sm-block {
    display: block !important;
  }
  .leaderboard {
    padding: 40px 0;
  }
  .sm-28 {
    width: 25px;
  }

  .name {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .score {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .number {
    font-size: 16px;
    letter-spacing: 1px;
  }

  .m-profile {
    padding: 140px 0 !important;
  }

  .block-description .iq-title {
    font-size: 1.2rem !important;
  }

  .banner-container .tvshows-slider .swiper-bg {
    height: auto !important;
  }
  .d-md-none {
    display: none !important;
  }
}

@media (max-width: 990px) {
  .footer-standard {
    flex-wrap: nowrap !important;
  }
  .description-text {
    left: 30px;
    transform: translateY(-50%);
  }
  .hover-buttons .btn {
    font-size: 0.9em !important;
    letter-spacing: 1px !important;
  }
  .slide-item .img-box {
    height: 145px !important;
    display: flex;
    justify-content: center;
    overflow: hidden !important;
  }
  .slide-item .img-box img {
    height: 100% !important;
    width: auto !important;
    /* width: 100% !important; */
    max-width: none !important;
  }
}

@media (max-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .game-description {
    background: linear-gradient(
      95deg,
      rgba(4, 37, 84, 0.685) 0%,
      rgba(17, 34, 72, 0.605) 15%,
      rgba(228, 0, 42, 0.085) 100%
    ) !important;
  }
  .games-banner .swiper-banner-container {
    margin-top: 66px !important;
  }
  .main-title {
    font-size: 1.5em !important;
    margin-bottom: 10px;
  }
  .block-description {
    left: 1em !important;
  }
}

@media (max-width: 500px) {
  .xs-block {
    display: block !important;
  }
  .footer-standard {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 479px) {
  #top-10 li.slide-item .block-description .hover-buttons {
    display: none !important;
  }

  #top-10 .block-description {
    left: 2em !important;
  }

  .item div {
    font-size: 75px;
    left: -11px;
    z-index: 9999;
    bottom: 8px;
  }

  .movie-time {
    margin-top: 0 !important;
    margin-bottom: 2px !important;
  }

  .slider-ratting {
    margin-top: 0 !important;
  }

  li.slide-item .block-images .hover-buttons {
    display: block !important;
  }
}

.game-win-lose-modal-header span {
  font-size: 1.5rem;
}
