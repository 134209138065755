.embed_responsive_video{
    width: 100%;
    
}
.embed_responsive_video .video-js{
    padding-top: 0 !important;
    height: 583px !important;

}
/* Max and min width */
@media (max-width: 575px) {
    .embed_responsive_video{
        height: 400px;
    }
}

/* min width 576 and max width 767 */
@media (min-width: 576px) and (max-width: 767px) {
    .embed_responsive_video{
        height: 400px;
    }
}

/* min width 768 and max width 991 */
@media (min-width: 768px) and (max-width: 991px) {
    .embed_responsive_video{
        height: 400px;
    }
}

/* min width 992 and max width 1199 */
@media (min-width: 992px) and (max-width: 1199px) {
    .embed_responsive_video{
        height: 500px;
    }
}

/* min width 1200 */
@media (min-width: 1200px) {
    .embed_responsive_video{
        height: 600px;
    }
}